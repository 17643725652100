<template>
  <div
    class="af-header is-flex is-align-items-center px-5"
    :class="{ 'is-justify-content-center': onlyLogo }"
  >
    <router-link
      :to="isMortgage ? '/user/mortgage-applications' : '/user/applications'"
      class="logo-router"
    >
      <img
        class="instacash-logo cursor-pointer"
        :src="
          isBrand(CUSTOM_APPEARANCE.MBH)
            ? require('@/assets/images/mbh-logo.svg')
            : require('@/assets/images/instacash-logo-black.svg')
        "
        alt="Logo"
      />
    </router-link>

    <div class="ml-auto is-flex is-align-items-center" v-if="!onlyLogo">
      <div
        class="mr-4 is-hidden-touch has-separator"
        v-if="loggedIn && timeLeft"
      >
        <p class="has-text-grey-3 is-size-7 mb-0">
          {{ $t("af:header.title.time_left") }}
        </p>

        <span class="has-text-grey-darker" :style='{"fontSize":"14px"}'>
          {{ secondsToMinutes(timeLeft) }}
        </span>
      </div>

      <div
        class="ml-4 mr-4 is-hidden-touch"
        :class="{ 'has-separator': loggedIn }"
      >
        <p class="has-text-grey-3 is-size-7 mb-0">
          {{ $t("af:header.title.call_us") }}
        </p>

        <a
          class="has-text-weight-medium phone-number has-text-grey-dark"
          :href="`tel:${phoneNumber}`"
          >{{ phoneNumber }}</a
        >
      </div>

      <div class="ml-4" v-if="loggedIn && !onlyLogo">
        <div
          class="user-name-container has-text-weight-medium is-inline-flex is-align-items-center"
          @click="onProfileClick"
        >
          <b-icon
            icon-pack="fa"
            icon="user-circle"
            size="is-large"
            class="user-profile"
          ></b-icon>
          <span class="todo-badge" v-if="todoCount > 0">
            {{ todoCount }}
          </span>
          <div class="is-hidden-touch user-name">{{ profileName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "@/plugins/i18n";
import { CUSTOM_APPEARANCE } from "@/utils/const";
import { isBrand, secondsToMinutes } from "@/utils/util";

export default {
  name: "TheHeader",
  methods: {
    secondsToMinutes,
    isBrand,
    onProfileClick() {
      if (this.todoCount > 0) {
        this.$router.push({
          name: "UserTodos",
        });
      } else {
        this.$router.push({
          name: "Profile",
        });
      }
    },
  },
  props: {
    onlyLogo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    CUSTOM_APPEARANCE() {
      return CUSTOM_APPEARANCE;
    },
    ...mapGetters({
      displayName: "session/displayName",
      email: "session/email",
      phone: "session/phone",
      loggedIn: "session/isReady",
      todoCountLoan: "myTodo/getCount",
      todoCountMortgage: "myTodo/getMortgageCount",
      timeLeft: "session/timer",
    }),
    todoCount() {
      return this.todoCountLoan + this.todoCountMortgage;
    },
    profileName() {
      if (!this.displayName) {
        if (!this.email) {
          return this.phone;
        } else {
          return this.email;
        }
      } else {
        return this.displayName;
      }
    },
    phoneNumber() {
      if (this.isMortgage) {
        return i18n.t("af:header.title.mortgage_phone_number");
      } else {
        return i18n.t("af:header.title.phone_number");
      }
    },
    isMortgage() {
      return !!this.$route.meta.mortgage;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.af-header {
  height: 44px;
  background: white;
  margin: 0;

  .logo-router {
    z-index: 10;
  }

  .phone-number {
    font-size: 14px;
  }

  .user-name-container {
    font-size: 14px;
    cursor: pointer;
    display: flex;
  }

  .user-profile {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
  }

  .has-separator {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      right: -1rem;
      height: 80%;
      top: 10%;
      width: 1px;
      background: #dfe6ef;
    }
  }

  .todo-badge {
    position: absolute;
    margin-left: 1rem;
    margin-bottom: 1.5rem;
    height: 1.25rem;
    width: 1.25rem;
    text-align: center;
    background-color: $red;
    border-radius: 16px;
    color: white;
    font-size: 14px;
    padding-top: 0.1rem;
  }
}

@media only screen and (min-width: 769px) {
  .af-header {
    height: 60px;
  }
}
</style>
